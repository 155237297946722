.box-component{
    width: 100%;
    background-color: #ffffff;
    position: relative;
    overflow-wrap: anywhere;

    &.with-border-and-shadow{
        border: solid 1px #c3c3c399;
        box-shadow: 0 0 10px 1px #c3c3c399;
        border-radius: 10px;

        &.normal{
            padding: 24px;
        }
    
        &.small{
            padding: 12px;
    
            .ant-card-body{
                min-height: 140px !important;
            }
        }
    
        &.ultra-small{
            padding: 0px;
        }
    }

    &.without-border-and-shadow{
    }

    
}