.google-drive-component{
    .bread-crumb{
        cursor: pointer;
    }
}

.box-google-drive{
    margin-bottom: 10px;

    .header-toolbar,
    .bread-crumb,
    .body-actions{
        border-bottom: 1px solid rgba(0, 0, 0, 0.06); 
        padding-bottom: 10px;
        margin-bottom: 15px;
    }

    .header-toolbar{
        text-align: right;
        width: 100%;

        .ht-button{
            margin-left: 5px;
        }
    }

    .body-actions{
        .uploader{
            .uploader-item{
                display: flex;
                text-align: center;
                align-items: center;
            }
        }

        .folder-creations{
            display: flex;
        }
    }

    .item-list{
        padding: 5px;
        &:hover{
            background-color: rgba(0, 0, 0, 0.06);  
        }

        .item{
            .item-additional-info{
                .item-additiona-info-shared{
                    border: solid rgba(0, 0, 0, 0.06);
                    border-width: 1px 0 0px 0; 
                    padding: 10px 0;
                    margin: 15px 0;
                }
            }
    
            .item-action-buttons{
                    button{
                        margin-right: 5px;
                    }
            }
        }
    }
}

.shared-options{
        .actions{
            text-align: right;
        }
}

.no-initial-folder-defined{
    text-align: center;

    .btn-save-folder-name{
        min-width: 300px;
        margin: 10px 0;
    }

    .alert{
        text-align: justify !important;
    }
}

.google-drive-buttons-actions{
    text-align: right;
    button{
        margin: 0 5px;
        min-width: 90px;
    }
}

.google-drive-navigators{
    text-align: center;
    .google-drive-navigation-button{
        margin-top: 5px;
        margin-left: 10px;
    }
}

.google-drive-file-name{
    display: flex;
    align-items: center;

    img{
        margin-right: 15px;
    }
}


.browser{
    display: flex;


    
    .folder,
    .file{
        cursor: pointer;
        width: 150px;
        padding: 4px;

        &:hover{
            border: solid 1px;
            border-color: rgba(0, 0, 0, 0.1);
            border-radius: 3px;
            background-color:rgba(0, 0, 0, 0.06)
        }

        .file-icon{
            font-size: 3em;
        }

        span{
            display: block;
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-top: 5px;
            font-weight: 600;
            text-align: center;
        }
    }
}