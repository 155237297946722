.edit-category-component{
    .btn-actions{
        margin-top: 10px;

        text-align: right;
        button{
            width: 100px;
            margin-left: 5px;
            margin-top: 5px;
        }

        @media (max-width: 400px){

            button{
                width: 100%;
                margin: 0 0 5px 0;
            }

        }
    }
    
}