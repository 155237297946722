.centers-component{
    padding: 20px;
    @media (max-width: 400px){
        padding: 20px 10px 10px 10px;
        
    }

    .btn-actions{
        text-align: center;
        button{
            min-width: 100px;
            margin-bottom: 3px;
            display: block;
        }
    }

    .sport-center-status{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;

        &.active{
            background-color: green;
        }

        &.inactive{
            background-color: red;
        }
    }
}