.btn-download-attendances{
    

    &.btn-secondary{
        margin-top: 5px;
    }
    
    width: auto; 
    background-color: green; 
    border-color: green;
}

.groups-component{
    .tag-name{
        margin-bottom: 4px;
        @media (max-width: 400px){
            width: 100%;
            
        }
    }
    
    .info-group-name{
        label{
            display: block;
        }
    }


}

.groups-edit-component{
    .buttons{
        text-align: right;

        button{
            margin-left: 5px;
        }
    }
}