.dashboard-v2{
    padding: 20px;

    //Animación para fondos de las tarjetas de inscripciones y atletas
    @keyframes background-card-move{
        0%{background-position:0%}
        50%{background-position:100%}
        100%{background-position:90%}

    }


    .ant-card-body{
        background: linear-gradient(to bottom left, #cc1a1a00 36.8%, #66ff0007 0) top/144% 100%, center/114% 26%;

        animation-name: background-card-move;
        animation-duration: 4s;
        animation-fill-mode: forwards;
    }

    .task-resume{
        margin-top: 10px;
        list-style: none;
        padding: 0;

        .icon{
            margin-right: 10px;
        }
    }

    .resume-box{
        display: flex;
        flex-direction: row;
        align-items: center;

        .resume-icon{
            padding: 0 5px;
            font-size: 3em;
        }

        .resume-label{
            font-size: 1.5em;
            font-weight: 700;
            padding: 0 15px;

            small{
                display: block;
                font-size: 12px;
            }
        }
        
    }

    .resume-amount{
        max-width: 100px;
        display: inline-block;
        padding: 3px 8px;
    }

    .last-inscription{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70px;
        background-color: white;
        border-radius: 10px;
        border: solid 1px #fcfcfc;
    }
}