.divider-no-margin{
    margin: 3px 0 !important;
}

.app-menu{
    

    .antd-menu{
        padding: 10px 0 0 10px   
    }

    @media (max-width: 370px) {
        .current-page{
            display: none;
        }
    }
    
}