.close-seasons-component{
    .chk-close-season{
        display: block;
    }

    .btn-close-season{
        display: block;
        margin-top: 10px;
    }

    .reload-active-season{
        margin-left: 5px;
    }
}