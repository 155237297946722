// Estos estilos se ponen fuera porque AntD los renderiza fuera del div que contiene la etiqueta .table-pagination
.icon-filter-applied{
    color: green;
    font-weight: bold !important;
    font-size: 1em !important;
}

.filter-label{
    font-weight: 600;
}


.table-pagination{

    @keyframes fadeOut {
        0% {
          opacity:1;
        }
        100% {
          opacity:0;
        }
      }

    .fade-out {
        animation: fadeOut ease 2s;
        -webkit-animation: fadeOut ease 2s;
        -moz-animation: fadeOut ease 2s;
        -o-animation: fadeOut ease 2s;
        -ms-animation: fadeOut ease 2s;
        animation-fill-mode: forwards;
      }

    .mobile-view{
        .file-data{
            margin-bottom: 10px;

            .icon{
                font-size: 15px;
                color: #8c8c8c;
                clear:both;
                display: block;
                margin-bottom: 3px;
            }
        }
        
    }

    

    .tp-filter-buttons{
        margin-bottom: 5px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button{
            text-transform: capitalize;
        }

        button:nth-child(1){
            margin-left: 5px;
            min-width: 100px;
        }

        @media (max-width: 590px) {
            &.in-columns{
                flex-direction: column;
                justify-content: end;
                align-items: flex-end;
                button{
                    margin: 0 0 5px 0 !important;
                    width: 100% !important;
                }
            }
        }

       
    }
    
    .user-resume{
        background-color: white !important;
        padding: 3px;
    }
    table{
        tbody{
            .ant-table-row:nth-child(even){
                background: #fcfcfc !important;

                .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first{
                    background: #fcfcfc !important;

                }
            }
        }
    }


    width: 100%;
    
    .pagination{
        float: right;
        margin-top: 10px;
    }

    .bottom-spacer{
        margin-top: 100px;
    }
}