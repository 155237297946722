.trainers-page{
    padding: 20px;

    .tag-name{
        margin-bottom: 4px;
        @media (max-width: 400px){
            width: 100%;
            
        }
    }


    .enrollments-card{
        padding: 8px;
        .ant-divider{
            margin: 10px 0 !important;
        }
    
        .header-card{
            width: 100%;
            display: flex;
    
            .mobile-menu-actions{
                position: absolute;
                right: 8px;
            }
    
            .mobile-enrollment-id{
                margin-left: 5px;
            }
        }
        .body-card{
            display: flex;
    
            .avatar{
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .info{
                padding: 0 10px;
                
                display: flex;
                flex-direction: column;
            }
        }
    }

    
}