#step-by-step{
    box-shadow: rgba(0, 0, 0, 0.7) 0px 0px 0px 9000px;
    padding: 10px;
    position: fixed;
    top: 0vh;
    left: 0vw;
    margin: 0;
    background-color: transparent;
    border-width: 150vh 150vw;
    border-radius: 100%;
    transition: all 0.4s ease 0s;
    z-index: 2000;

    &.finish{
        display: none;
        visibility: hidden;
    }

    &.left{
        #step-by-step-container{
            margin-left: -330px;
            margin-top: -30px;

            &::before{
                right: -5px;
                top: calc(50% - 7px);
            }
        }
    }

    &.right{
        #step-by-step-container{
            margin-top: -30px;
            margin-left: 105px;

            &::before{
                right: calc(100% - 6px);
                top: calc(50% - 6px);
            }
        }
    }

    &.top{
        #step-by-step-container{
            margin-left: -105px;
            margin-top: -175px;

            &::before{
                right: 50%;
                top: calc(100% - 5px);
            }
        }
    }

    &.bottom{
        #step-by-step-container{
            margin-left: -105px;
            margin-top: 110px;

            &::before{
                right: 50%;
                top: -5px;
            }
        }
    }

    &.topRight{
        #step-by-step-container{
            margin-top: -110px;
            margin-left: 105px;

            &::before{
                right: calc(100% - 6px);
                top: calc(50% + 54px);
            }
        }
    }

    #step-by-step-container{
        position: absolute;
        background-color: white;
        border-radius: 3px;
        width: 300px;
        
        padding: 10px;

        &::before{
            content: " ";
            position: absolute;
            background-color: white;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
        }

        .header,
        .body,
        .footer{
            width: 100%;
        }

        .header{
            border: solid #f0f0f0;
            border-width: 0 0 2px 0;
            margin-bottom: 5px;
            padding-bottom: 2.5px;
        }
        
        .body{
            padding: 5px 0;
        }

        .footer{
            border: solid #f0f0f0;
            border-width: 2px 0 0 0;
            margin-top: 5px;
            padding-top: 2.5px;

            .navigation{
                margin-top: 5px;
                .prev{
                    width: calc(100% / 3 - 1%);
                    margin-right: 1%;
                }
                
                .next{ 
                    width: calc(100% / 3 - 1%);
                    margin-right: 1%;
                    
                }
                
                .finish{ 
                    width: calc(100% / 3);
                }
            }

        }
    }

    .step{
        position: absolute;
        color: white;
    }
}
