.set-attendances{
    .alert-5-minutes{
        margin-top: 10px;
    }
    
    .info{
        padding-top: 5px;
        border: solid #cfcfcf;
        border-width: 0 0 1px 0;
        padding-bottom: 10px;

        

        .actions{
            text-align: left;
            .btn-action{
                margin-right: 5px;
                margin-top: 5px;

                &.to-right{
                    float: right;
                    margin-right: 0;
                }
            }

            .last-mark{
                margin: 5px 0;
            }
        }

        .athlete-data{
            span{
                display: block;
            }
        }

        .emergency-data{
            margin-top: 10px;

            .contact-data{
                span{
                    display: block;
                }
            }
        }

        
        
    }
}