
.subscription-payment-data{

    .select-sport-center{
        width: 100%;
    }

    .select-change-size-group{
        margin-bottom: 15px;

        .select-size{
            max-width: 300px;
            min-width: 200px;
        }

        .skin-select{
            border: solid 1px gainsboro;
            min-height: 32px;
            min-width: 31px;
            display: inline-block;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding-top: 7.5px;
        }
    }

    .stripe{
        &.trialing{
            display: inline-block;
            font-size: 1.5em;
            color:khaki;
        }

        &.active{
            display: inline-block;
            font-size: 1.5em;
            color:lightgreen;
        }

        &.canceled{
            display: inline-block;
            font-size: 1.5em;
            color:rgb(247, 38, 38);
        }

        &.trialing-leyend{
            display:block;
            margin-bottom: 15px;
        }
    }

    .sub-title{
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
    }

    .leyend{
        font-size: 13px;
        list-style: none;
        padding: 5px;
    }

    .actions-title{
        font-weight: bold;
        display: block;
    }

    .resume{
        text-align: justify;
    }

    .enrollment-status-tag{
        width: 90px;
        text-align: center;
        zoom: .9;
    }

    .selector,.button-action{
        min-width: 100%;
        margin-bottom: 10px;

        &.cancel-payments:not(disabled){

        }

        &:disabled{
            background: #f5f5f5 !important;
            border-color: #d9d9d9 !important;
        }
        &.refund:not(disabled){
            background:#FFC300;
            border-color:#FFC300;
        }

        &.cancel:not(disabled){
            background: #FF5733;
            border-color: #FF5733;
        }

        &.free:not(disabled){
            background:rgb(45,183,78);
            border-color:rgb(45,183,78);
        }
    }

    .detail-info-box-header{
        background-color: #fcfcfc;
        border: solid 1px #f0f0f0;
        border-radius: 3px;
        border-top-color: #0856a16e;
        border-top-width: 2px;
        box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);
        margin-top: 1.5em;
        margin-bottom: .5em;
        padding-bottom: 1em;

        .ant-divider-horizontal{
            margin: 10px 0 24px 0 !important;
        }

        .location-category-data{
            text-align:center;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding-top: 14px;
        }

        
        .separator{
            font-size: 2em;
            height: 55px;
            padding-left: .25em;
            padding-right: .25em;
        }
    }

    .payment-list-container{
        padding: 1em;
    }

    .detail-info-box{
        padding-left: 0px !important;
        padding-right: 0px !important;
        background-color: #fcfcfc;
        border: solid 1px #f0f0f0;
        padding: 1em;
        border-radius: 3px;
        border-top-color:#0856a16e;
        border-top-width: 2px;
        box-shadow: 0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07);
        margin-top: 1.5em;
        margin-bottom: .5em;

        @media (max-width: 1500px) and (min-width: 1199px) {
            min-width: 100% !important;
            margin-left: 0 !important;
        }

        .title{
            box-shadow: inset 0 -1px 0 #e6ebf1;
            padding: 12px;
        }

        span{
            display:block;
        }

        table{
            .table-header{
                font-weight: bold;
                min-width: 100px;
                padding-left: 2em;
            }

            .table-content{
                padding-left: 3em;
            }
        }
    }
}