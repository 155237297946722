.emails-page{
    padding: 20px;

    .buttons{
        text-align: right;
        
        button{
            min-width: 150px;
        }
    }
}