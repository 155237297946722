.product-list{
    .sport-center-name{
        display: block;
        font-weight: 700;
    }

    .counter-actived,
    .counter-archived{
        display: block;
    }

    .active{
        width: 9px;
        height: 9px;
        max-width: 9px;
        max-height: 9px;
        min-width: 9px;
        min-height: 9px;
        border-radius: 50%;
        background-color: rgba(0, 255, 0, 0.5);
        margin-left: 3px;
        cursor: pointer !important;
        float:left;
        margin-top: 7px;
    
        &.not{
            background-color: rgba(255, 0, 0, 0.5) !important;
        }
    }

    .add-discount,
    .refresh-products,
    .add-product{
        float: right;
        top: 0;
        margin-bottom: 10px;
    }

    .add-product,
    .refresh-products{
        margin-right: 10px;
    }

    .btn-update-product{
        margin-top: 10px;
        float: right;
    }
}