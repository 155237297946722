.user-resume{

    

    .btn-actions-over-user{
        margin-right: 5px;
        margin-top: 5px;

        &.ban{

        }
        &.logout{

        }
        &.connect-like{

        }
    }

    .margintop10px{
        margin-top: 10px !important;
    }

    .button-modify-data{
        position: absolute;
        right: 10px;
        top: 20px;
    }

    .btn-refresh-data{
        position: absolute;
        right: 40px;
        top: 60px;
    }

    .actions-card{
        .btn-action{
            margin-left: 10px;

            &.refund{
                background-color: #ffc300;
                color: white;
            }
        }
    }

    .card-expired{
        color: red;
        font-weight: bold;
    }

    .ant-drawer-open{
        transform: translateX(180px) !important;
    }

    .money-resume{
        margin-top: 10px;

        .amount{
            display: block;
            font-size: 1.5em;
            font-weight: 600;
        }

        .total-payments{
            display: block;
        }
    }


    .show-all-payments-btn{
        position: absolute;
        right: 0;
    }

    .account-title,
    .account-info{
        display: block;
    }

    .account-title{
        margin: 8px 0 5px 0;
        font-size: 1.2em;
    }

    .user-name{
        display: flex;
        align-items: center;
        justify-content: start;
        font-size: 2em;
        font-weight: bold;

        .dot-online, 
        .dot-offline{
            font-size: 1.5em;
            padding: 0;
            margin: 0;
        }

        .dot-online{ color: green;}
        .dot-offline{ color: red;}
        }

    .email{
        display: block;
        font-size: 1.5em;
        color:#687385;
    }

    .detail-title{
        border-bottom: solid 1px #f0f0f0;
        font-weight: bolder;
        font-size: 1.3em;
        margin-top: 30px;
        padding-bottom: 15px;
        margin-bottom: 0px;
    }

    .icon{
        display: flex;
        align-items: center;
        .additional-info-arrow{
            font-size: 1.1em;
            margin-right: 10px;
            font-weight: 700;
            min-width: 13px;
        }
    }
    

    .default-payment-method{
        margin-left: 5px;
    }

    .avatar-icon{
        padding-right: 15px;
    }

    .payment-info,
    .bank-info,
    .card-info{
        display: flex;
        align-items: center;
        width: 100%;
        padding-bottom: 5px;
    }

    .error-icon,
    .refunded-icon,
    .bank-icon, 
    .card-icon{
        font-size: 25px;
        color: white;
        padding: 5px;
        border-radius: 3px;
        margin-right: 15px;

        &.free{
            background-color: #00579f8f !important;
        }
    }

    .bank-icon{
        background: #2e9f00;
    }

    .card-icon{
        background: #00579f;
    }

    .refunded-icon{
        background: #fdee1e;

    }

    .error-icon{
        background: #fd401e;

    }

    .ultra-small{

        .mobile-enrollment-status{
            width: 100%;
        }
    }



    .user-card{
        padding: 13px 8px;
        border-bottom: solid 1px #f0f0f0;
        cursor: pointer;

        &:hover{
            background-color: #f6f8fa;
        }

        .ant-divider{
            margin: 10px 0 !important;
        }
    
        .header-card{
            width: 100%;
            display: flex;
    
            .mobile-inline-offline-user{
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .mobile-menu-actions{
                position: absolute;
                right: 8px;
            }
    
            .mobile-enrollment-id{
                margin-left: 5px;
            }
        }
        .body-card{
            display: flex;
    
            .avatar{
                display: flex;
                align-items: center;
                justify-content: center;
            }
    
            .info{
                padding: 0 10px;
                
                display: flex;
                flex-direction: column;
            }
        }
    
        .mobile-user-id{
            display: block;
            padding-bottom:3px;
        }
    
        .mobile-user-rol{
            display: flex;
        }
    
        .mobile-user-name{
            display: inline-flex;
            align-items: center;
            padding-bottom:3px;
        }
    }
}

