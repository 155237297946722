.enrollments{

    .enrollments-card{
        padding: 8px;
        .ant-divider{
            margin: 10px 0 !important;
        }

        .header-card{
            width: 100%;
            display: flex;

            .mobile-menu-actions{
                position: absolute;
                right: 8px;
            }

            .mobile-enrollment-id{
                margin-left: 5px;
            }
        }
        .body-card{
            display: flex;

            .avatar{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .info{
                padding: 0 10px;
                
                display: flex;
                flex-direction: column;
            }
        }
    }
    
    .search-input{
        margin-bottom: 5px;
    }

    .filter-buttons{
        width: 100%;
        button{
            margin-right: 10px;
            margin-bottom: 5px;
            width: 100px;
        }
    }

    .enrollment-status-tag{
        width: 90px;
        text-align: center;
        zoom: .9;
    }

    .material-delivered{
        color: green;

        &.not{
            color: red !important;
        }
    }
}