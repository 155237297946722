.profile{
    .form-buttons{
        text-align: right;

        button{
            min-width: 300px;

            @media (max-width: 500px) {
                min-width: 100%;
            }
        }
    }

   
}