.payments{

    .notify-payment{
        margin-left: 5px;
    }


    .payments-card{

        padding: 8px;
        .ant-divider{
            margin: 10px 0 !important;
        }

        .header-card{
            width: 100%;
            display: flex;

            .mobile-menu-actions{
                position: absolute;
                right: 8px;
            }

            .mobile-enrollment-id{
                margin-left: 5px;
            }
        }
        .body-card{
            display: flex;

            .avatar{
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 100px;
            }

            .info{
                padding: 0 10px;
                min-width: 100px;
                display: flex;
                flex-direction: column;
            }
        }

        .mobile-payment-id,
        .mobile-payment-description,
        .mobile-payment-date,
        .mobile-payment-amount{
            display: block;
            padding-bottom:3px;
        }

        .mobile-refund-text{
            text-align: right;
            position: absolute;
            right: 12px;
        }

        .mobile-payment-amount{
            text-align: right;
            font-size: 1.4em;
            font-weight: 600;

            &.payed{
                color: #2e962e;
            }

            &.error{
                color: #ff0000;
            }

            &.refunded{
                color: #ffc000;
            }
        }
       
    }

    .filter-buttons{
        width: 100%;
        button{
            margin-right: 10px;
            margin-bottom: 5px;
            width: 100px;
        }
    }
    
    .search-box{
        margin-bottom: 5px;
    }

    .payment-status-tag{
        width: 90px;
        text-align: center;
        zoom: .9;
    }

    .refund:not(disabled){
        background:#FFC300;
        border-color:#FFC300;
    }
}