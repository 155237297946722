.edit-season-component{
    .btn-actions{
        margin-top: 10px;
        text-align: right;
        button{
            min-width: 100px;
            margin-left: 5px;

            @media (max-width: 450px) {
                min-width: 100%;
                margin-bottom: 5px;
            }
        }
    }
}