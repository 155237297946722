$mask: polygon(-1px -1px, 103% -1px, 103% 78%, -1px 100%);

html{
    overflow: hidden;
}

.register{
    background-blend-mode: lighten;
    background-color: hsla(0,0%,100%,.40);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;

    @media (max-width: 768px) {
        overflow: auto;
    }

    .container{
        height: 90vh;
        box-shadow: 0 0 52px 5px rgba(0,0,0,.8);
        border-radius: 3px;
        overflow: auto;

        @media (max-width: 768px) {
            height: 100vh;
            border-radius: 0;
            overflow: auto;
        }
 
        .container-row{
            height: 100%;
            background-color:white;

            .form{
                background-color: white;
                min-height: 45%;
                padding: 45px 30px 10px 30px;


                .circle-title{
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    height: 70px;
                    width: 70px;
                    position: absolute;
                    align-items: center;
                    justify-content: center;
                    display:flex;
                    bottom: calc(100% - 75px);
                    z-index: 1;
                    top: 20px;

                    span{
                        font-weight: bold;
                        font-size: 1.5em;
                        color: white;
                    }
                }
            }

            .create-account{
                background-color: white;
                min-height: 10%;
                text-align: center;
            }
        }
    }
}