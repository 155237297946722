.theme{
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.4);
    background-blend-mode: lighten;
    
    .welcome-title{
        padding-left: 5px;
        padding-right: 5px;
        border-bottom: solid 1px;
        margin-bottom: 19px;
        border-color: #f0f0f0;
        box-shadow: 0px 10px 10px 0px rgba(0,0,0,.1);
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;

        @media (max-width: 599px) {
            
        }

        .title{
            @media (max-width: 599px) {
                font-size: 23px !important;
            }
        }
    }

    .container{
        box-shadow: 0 0 52px 5px rgba(0,0,0,.3);
        height: 100vh;
        min-height: 100vh;
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color:white;

        @media (max-width: 768px) {
            box-shadow: none;
        }
    }

    
}