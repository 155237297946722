.global-page{
    padding: 20px;
    scroll-behavior: smooth;

    .quick-access{
        position: fixed;
        z-index: 10;
        bottom: 0px;
        left: 0px;
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: white;
        text-align: center;
        justify-content: center;
        padding: 5px 0;
        box-shadow: 0 2px 8px #f0f1f2;
        border: 1px solid #f0f0f0;
        border-width: 1px 0 0 0;
        .link-to-quick-access{
            margin: 0 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            border: solid 1px;
            background: white;
            border-radius: 50%;
            width: 30px;
            min-width: 30px;
            height: 30px;
            min-height: 30px;
            color: white;
            padding: 18px;
        }

        .enrollments-qa{
            background: linear-gradient(0deg, #a10303 50%, #db4444 50%) !important;
            transition: background 5s;
        }

        .payments-qa{
            background: linear-gradient(0deg, #b1b403 50%, #ccd670 50%) !important;
            transition: background 5s;
        }

        .users-qa{
            background: linear-gradient(0deg, #76bb8d 50%, #90e2ab 50%) !important;
            transition: background 5s;
        }

        .athletes-qa{
            background: linear-gradient(0deg, #0099ff 50%, #41b3ff 50%) !important;
            transition: background 5s;
        }

        


    }

    .task-resume{
        margin-top: 10px;
        list-style: none;

        .icon{
            margin-right: 10px;
        }
    }
}