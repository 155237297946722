.frm-login{
    
    

    label{
        font-weight: 600;
        color: #bbb8b8 !important;
        text-transform: uppercase;
    }

    .btn-login{
        width: 100%;
        height: 50px;
        text-transform: uppercase;
        margin-top: 40px;
    }

    .other-login-actions{
        text-align: right;
        a{
            right: 30px;
        }
    }

    .error-login{
        text-align: center;

        a{
            color: red;
        }
    }


}
