.balance-component{

    @media (max-width: 767px){
        margin-top: 10px;
    }

    width: fit-content;
    background-color: #635bff;
    color: white;
    border-radius: 3px;
    padding: 5px 10px;
    text-align: center;

    .available,
    .connect-reserved,
    .pending{
        display: inline;
        cursor: pointer;
    }

    .title{
        display: block;
        padding: 0;
    }

    .link-to-stripe{
        color: white;
        text-decoration: underline dashed;
    }
}