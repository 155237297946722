.financing-data{
    .total-amount{
        padding-left: 40px;
    }

    ul{
        list-style: none;
    }
    .payed{
        font-weight: 700;
    }
    
    .discount-apply{
        display: block;
    }

    .advise{
        text-align: justify;
    }

    .initial-pay{
        margin-bottom: 10px;
    }
}