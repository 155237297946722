
.btn-reload-all{
    margin-left: 5px;
}
    
.hello{
    .ant-drawer-body{
        padding-top: 0 !important;
    }
    
    border-radius: 5px;
    padding: 20px !important;
    //background-image: radial-gradient(circle at -13.87% 32.88%, #074376 0, #0d5089 25%, #0d5691 50%, #11669e 75%, #1268a6 100%);

    .title{
        color: white !important;
        padding-top: 5px;
    }

    .last-connection{
        color: white;
        text-align: right;
        width: 100%;
        font-weight: 600;
        position: absolute; 
        bottom: 5px;
        padding-right: 1em;
        left: 0;
    }

    .client-logo{
        max-height: 60px;
        max-width: 25%;
    }
}