.btn-attendances-actions{
    text-align: right;
}

.wellcome-trainer-component{



    .task-resume{
        list-style: none;
        padding-left: 15px;
    }

    .my-day-container{
        .my-days{
            width: 100%;
            .my-day-clock{
                position: absolute;
                left: 4px;
                top: 6.5px;
            }

            .btn-attendances{
                position: absolute;
                right: 2px;
                top: 2px;

                &.btn-slim{
                    height: 20px;
                    padding: 1px;
                    font-size: 10px;
                }
            }
            .my-day{
                width: 100%;
                margin-bottom: 5px;
                border: solid 1px #cfcfcf;
                padding: 2.5px;
                background-color: rgba(0,0,0,.05);
                position: relative;
                padding-right: 30px;
                padding-left: 25px;

                .my-day-schedule{
                    display: block;
                }
            }
        }
    }

    .my-week-table{
        overflow-x: scroll;
        padding-bottom: 20px;

        .my-week-header{
            background-color: transparent !important;
            padding: 0 5px !important;
        }
    }
    
}