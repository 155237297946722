.categories-component{
    

    .enrollments-card{
        padding: 8px;

        .description{
            font-weight: 600;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .status-ball{
                border: solid;
                width: 10px;
                height: 10px;
                border: none;
                border-radius: 50%;
                margin-right: 5px;
                &.active{
                    background-color: green;
                }

                &.inactive{
                    background-color: red;
                }
            }
        }

        .ant-divider{
            margin: 10px 0 !important;
        }

        .header-card{
            width: 100%;
            display: flex;

            .mobile-menu-actions{
                position: absolute;
                right: 8px;
            }

            .mobile-enrollment-id{
                margin-left: 5px;
            }
        }
        .body-card{
            display: flex;

            .avatar{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .info{
                padding: 0 10px;
                
                display: flex;
                flex-direction: column;
            }
        }
    }
}