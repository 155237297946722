


.users{

    .user-card{
        padding: 8px;
        .ant-divider{
            margin: 10px 0 !important;
        }

        .header-card{
            width: 100%;
            display: flex;

            .mobile-inline-offline-user{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .mobile-menu-actions{
                position: absolute;
                right: 8px;
            }

            .mobile-enrollment-id{
                margin-left: 5px;
            }
        }
        .body-card{
            display: flex;

            .avatar{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .info{
                padding: 0 10px;
                
                display: flex;
                flex-direction: column;
            }
        }

        .mobile-user-id{
            display: block;
            padding-bottom:3px;
        }

        .mobile-user-rol{
            display: flex;
        }

        .mobile-user-name{
            display: inline-flex;
            align-items: center;
            padding-bottom:3px;
        }
    }
    
    .search-bar{
        margin-bottom: 5px;
    }

    .has-table-elements{
        .ant-table-body {
            min-height: 250px;
          }
    }

    .loged{
        width: 9px;
        height: 9px;
        max-width: 9px;
        max-height: 9px;
        min-width: 9px;
        min-height: 9px;
        border-radius: 50%;
        background-color: rgba(0, 255, 0, 0.5);
        margin-left: 3px;
        cursor: pointer !important;

        &.not{
            background-color: rgba(255, 0, 0, 0.5) !important;
        }

        &.banned{
            background-color: rgba(255, 115, 0, 0.5) !important;
        }
    }

    .user-type-tag{
        text-align: center;
        zoom: .9;
        min-width: 90px;
        
    }

    .contact-data{
        span{
            display: block;
            zoom: .9;
        }
    }
}