.app-header{
    position: relative;
    z-index: 10;
    max-width: 100%;
    background: #fff;
    box-shadow: 0 2px 8px #f0f1f2;
    min-height: 64px;
    
    @media(max-width: 768px){
        padding-top: 10px;
    }

    .balance-container{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo-container,.search-container{
        display: flex;
        align-items: center;
    }

    .logo-container{
        padding-left: 20px;
        justify-content: left;
        @media(max-width: 768px){
            justify-content: center;
            padding-left: 0;
        }

        text-align: center;
        .logo{
            max-height: 50px;
            max-width: 160px;
        }
    }

   
}