
.mobile-fixed{
    z-index: 100;
    @media (min-width: 991px) {
        position: fixed !important;
        
        height: 100% !important;
        top: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;

        
    }

    @media (max-width: 768px) {
        position: fixed !important;
        
        height: 100% !important;
        top: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;

        &.ant-drawer-open{
            width: 100% !important;
        }

        .ant-drawer-content-wrapper{
            width: 100% !important;
        }
    }
}