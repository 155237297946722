.action-buttons-component{
    .btn-actions{
        display: flex;
        &.row{
            flex-direction: row;
            button{
                margin-left: 5px;
            }
        }

        &.column{
            flex-direction: column;
            button{
                margin-bottom: 5px;
            }
        }
        
        button{
            min-width: 100px;
        }
    }
}