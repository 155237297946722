.register-form{
    .form-register-accordion{
        text-align:left;

        .anticon{
            margin-right: 10px;
        }
    }

    h4,h5{
        padding:0;
        margin:0 !important;
    }
    .rf-header, .rrss-registers{
        text-align:center;
    }
}

.btn-register{
    width: 100%;
    margin-top: 10px;
}

.btn-register-container{
    width: 100%;
}

.subtitle{
    display: block;
}