.welcome-component{

    .btn-show-advanced-statistics{
        text-align: right;
        width: 100%;
        button{
            width: 32px;
            text-align: center;
            padding: 0;
        }
    }

    .advanced-statistics{
        .super-badge{
            width: 100%;
        }
    }

    .seasons-selector{
        margin-bottom: 20px;

        .active-season{
            font-weight: 700;
        }

        label{
            font-weight: 600;
        }

        .season-selector{
            width: 365px;
            max-width: 100%;
        }

        .season-selector:first-child{
            margin-right: 10px;
        }

        .season-selector:last-child{
            margin-left: 10px;
        }
    }

    .btn-reload-statistics{
        margin-left: 5px;
    }
}

.ant-progress-outer{
    margin-right: 0 !important;
    padding-right: 0 !important;
}

.ant-progress-text{
    margin-left: 0 !important;
    display: block !important;
}

.tag-week-class{
    word-wrap: break-word;
}