.customization-component{
    .cfg-own-configuration-alert{
        margin: 10px 0;
    }

    .btn-actions{
        margin-top: 10px;
        width: 100%;
        text-align: right;
    }

    .thumbnails{
        width: 20px;
        height: 20px;
    }

    .actual-color{
        margin-left: 10px;
        width: 20px;
        height: 12px;
        content: "";
    }
    
    .switch-smtp-imap{
        margin: 10px 0;
    }
}