.my-waves{
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 1;
  height: 400px;
    /*Colores obtenidos de: https://loading.io/background/m-wave/
    y waves de https://dev.to/theliquidcharcoal/pure-html-animation-animate-svg-with-animate-2a5m
    */
div.waves.wave-1{
    opacity: .7;
    z-index: 98;
}

div.waves.wave-2{
    opacity: .5;
    z-index: 99;
}

div.waves.wave-3{
    opacity: .3;
    z-index: 100;
}
  
  div.waves{
    width: 100%;
    bottom: 0;
    position: fixed;
    z-index: -1;
    
  }

  svg{
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    animation-name: move-left;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .spacer{
    height: 100px;
    width: 100%;
  }
  
  @keyframes example {
    0%   {
      left:0px;
      top:0px;}
    
    50%  {
      left:-2000px;
      top:200px;}
    
    100% {
      left:0px; 
      top:0px;}
  }
  
  
}