
//Animación para fondos de las tarjetas de inscripciones y atletas
@keyframes background-card-move{
    0%{background-position:0%}
    50%{background-position:100%}
    100%{background-position:50%}

}

//Animación para botones de las tarjetas de inscripciones
@keyframes background-card-enrollments-buttons{
    0%{background-position:0%}
    50%{background-position:100%}
    100%{background-position:50%}
}