.divider-no-margin{
    margin: 3px 0 !important;
}



.app-menu{
    &.sticky{
            position: fixed;
            top: 0px;
            z-index: 10;
            width: 100%;
            left: 0px;
            transition: background .3s ease-in;
    
    }

    .antd-menu{
        padding: 10px 0 0 10px   
    }

    @media (max-width: 370px) {
        .current-page{
            display: none;
        }
    }
    
    
    
}