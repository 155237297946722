

.helper-balls{

    .created-by-omnicode{
        display: none;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: rgba(255, 38, 137, 1);
        text-align: center;
        color: white;
        height: 25px;

        @media (max-width: 500px){
            display: initial;
        }
    }

    .h-section-name{
        display: none;
        visibility: hidden;
        
        @media (max-width: 768px) {
            position: absolute;
            bottom: 0;
            color: rgb(39,39,39);
            font-size: 12px;
            display: inline-block;
            visibility: visible;
            padding-bottom: .3em;
            font-weight: 600;
        }
        
    }

    @keyframes move-in{
        from { right: -100px }
        to { right: 20px }
    }

    animation-name: move-in;
    animation-duration: 1s;
    position: absolute;
    z-index: 10;
    list-style: none;
    right: 20px;
    padding: 0;

    @media (max-width: 768px) {
        top: 10px !important;
    }

    &.dashboard-balls{
        top: 180px;
        background-color:transparent;

        @media (max-width: 1200px) {
            z-index: 10;
            animation-name: move-in;
            animation-duration: 1s;
            list-style: none;
            right: 30px;
            padding: 0;
            display: flex;

            justify-content: center;
            left: 0;
            position: fixed;
            background-color: white;
            width: 100%;
            top: calc(100% - 100px) !important;
            box-shadow: 0 5px 14px rgba(0,0,0,.5);
            height: 100px;
            align-items: center;

           
        }

        @media (max-width: 500px){
            top: calc(100% - 100px - 25px) !important;
            .h-heart{
                display: none;
            }
        }
    }



    li:not(.created-by-omnicode){
        @media (max-width: 500px){
            height: 40px;
            width: 40px;
        }

        &.h-heart{
            color: red !important;
            position: fixed;
            bottom: 20px;

            &:hover{
                background-color: red;
                color: white !important;
            }

            @media (max-width: 1200px) {
                position: initial;
                right: 100px;
            }

            @media (max-width: 991px) {
                right: 10px;
            }
        }

        color: black;
        height: 50px;
        width: 50px;
        background-color: white;
        margin: 10px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 5px 14px rgba(0,0,0,.1);
        box-sizing: border-box;
        cursor: pointer;
        font-size: 1.6em;
        transition: all .4s;

        &:hover{
            background-color: #4b7cf3;
            color: white;
            font-size: 2em;
            transition: all .4s;
        }

        &.h-logout{    
            background-color: rgba(255,0,0,.7);
            color: white;
        }

        &.h-enrollments{
            background-color: rgba(255,200,0,.8);
            color: white;
        }

        &.h-contact{
            background-color: rgba(29, 178, 65, .93%);
            color: white;
        }

        &.h-credit-card{    
            background-color: rgba(0,0,255,.7);
            color: white;
        }

        &.disabled{
            background-color: rgba(0,0,0,.5) !important;
            cursor: not-allowed;
        }

        &.h-notifications{    
            background-color: #FFC107;
            color: white;

            

            .h-alerts-count{
                position: absolute;
                border-radius: 100%;
                background-color: red;
                width: 25px;
                height: 25px;
                font-size: 12px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20px;
                margin-top: 20px;

                &.no-alerts{
                    display:none;
                }
            }
        }

        &.h-rfea{
            background-color: #5b6d66;
            color: white;
        }

        
        
    }
}

.routine-helper{
    position: fixed;
    height: 3em;
    bottom: 0;
    background-color: red;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
}