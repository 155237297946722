.cookies-modal{
    p{
        text-align: justify;
    }
}

.btn{
    margin-left: 5px;

    &.decline{
        background-color: #d44040;
        border-color: #d44040;
        color: white;
    }
}

.cookies{
    top: 0;
    position: absolute;
    z-index: 1001;
    background-color: white;
    width: 100%;
    padding: 20px;

    &.hidden{
        visibility: hidden;
    }

    p{
        text-align: justify;
    }

    .buttons{
        text-align: right;

        
    }

    
}